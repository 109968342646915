<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent scrollable max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" dark color="indigo">
          <v-icon dark> mdi-plus </v-icon> Tambah pelatihan
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Pelatihan</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Pelatihan"
                  required
                  :counter="50"
                  v-model="namaPelatihan"
                  :error-messages="errMsg"
                  @input="$v.namaPelatihan.$touch()"
                  @blur="$v.namaPelatihan.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="role_id == 1">
              <v-col cols="12">
                <v-select
                :options="scopeTeacher.data"
                :reduce="name => name.id"
                v-model="select_teacher"
                @search="query => (find = query)"
                label="name"
                outlined
              ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark v-if="loading">
            <v-progress-circular
              :size="20"
              :width="4"
              color="white"
              indeterminate
              class="mb-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn color="indigo" dark @click="addEvent" v-else> Tambah </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
// import gql from "graphql-tag";
import { CREATE_EVENT, GET_EVENT } from "../../../graphql/Event";
import { GET_TEACHER, } from '../../../graphql/Teacher'
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  apollo: {
    scopeTeacher: {
      query: GET_TEACHER,
      variables() {
        return {
          page: this.page,
          first: this.limit,
          search: this.search
        };
      },
      error(err) {
        console.log(err);
      }
    }
  },
  name: "addEven",
  mixins: [validationMixin],
  validations: {
    namaPelatihan: {
      required,
      maxLength: maxLength(50)
    }
  },
  components: {
    vSelect
  },
  mounted() {
    // console.log(this.scopeTeacher);
  },
  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.namaPelatihan.$dirty) return errors;
      !this.$v.namaPelatihan.maxLength &&
        errors.push("Nama pelatihan maksimal 50 karakter!");
      !this.$v.namaPelatihan.required &&
        errors.push("Nama Pelatihan harus di isi!");
      return errors;
    }
  },
  data: () => ({
    id: null,
    namaPelatihan: "",
    dialog: false,
    show: false,
    loading: false,
    role_id: parseInt(localStorage.getItem("id")),
    scopeTeacher: [],
    select_teacher: null,
    search: "",
    page: 1,
    limit: 10,
  }),
  methods: {
    addEvent() {
      this.$v.namaPelatihan.$touch();
      if (
        !this.$v.namaPelatihan.required &&
        this.$v.namaPelatihan.maxLength <= 50
      ) {
        return;
      } else {
        this.loading = true;
        const nama = this.namaPelatihan;
        const teacher_id = parseInt(localStorage.getItem("id"));
        const teacher = parseInt(this.select_teacher);
        if(this.role_id == 1){
          this.$apollo.mutate({
          mutation: CREATE_EVENT,
          variables: {
            nama: nama,
            teacher_id: teacher
          },
          update: (chace, { data: { createPelatihan } }) => {
            try {
              const data = chace.readQuery({
                query: GET_EVENT
              });
              const createTeach = createPelatihan;
              console.log(createPelatihan.id);
              data.allPelatihan.splice(0, 0, createTeach);
              chace.writeQuery({
                query: GET_EVENT,
                data
              });
            } catch (e) {
              console.log(e);
            }
            this.namaPelatihan = "";
            this.dialog = false;
            this.$v.namaPelatihan.$reset();
            this.$router.push(`event-detail/${createPelatihan.id}`);
            this.loading = false;
            Swal.fire({
              icon: "success",
              title: "Anda telah menambahkan pelatihan baru",
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            }).then(() => {});
          }
        });
        }
        else{
          this.$apollo.mutate({
            mutation: CREATE_EVENT,
            variables: {
              nama: nama,
              teacher_id: teacher_id
            },
            update: (chace, { data: { createPelatihan } }) => {
              try {
                const data = chace.readQuery({
                  query: GET_EVENT
                });
                const createTeach = createPelatihan;
                console.log(createPelatihan.id);
                data.allPelatihan.splice(0, 0, createTeach);
                chace.writeQuery({
                  query: GET_EVENT,
                  data
                });
              } catch (e) {
                console.log(e);
              }
              this.namaPelatihan = "";
              this.dialog = false;
              this.$v.namaPelatihan.$reset();
              this.$router.push(`event-detail/${createPelatihan.id}`);
              this.loading = false;
              Swal.fire({
                icon: "success",
                title: "Anda telah menambahkan pelatihan baru",
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              }).then(() => {});
            }
          });
        }
      }
    }
  }
};
</script>

<style></style>
