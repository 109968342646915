<template>
  <div class="min _bg-default">
    <nav class="pa-5">
      <AddEvent />
    </nav>
    <div class="temp-list d-flex">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Pelatihan
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div v-if="role == '2'">
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="pelatihanByTeacher"
            :search="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  fab
                  dark
                  small
                  color="cyan"
                  class="mr-3"
                  @click="viewEvent(item)"
                >
                  <v-icon dark small> mdi-eye </v-icon>
                </v-btn>
                <v-btn fab dark small color="red" @click="delEvent(item)">
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="allPelatihan.data"
            :search="search"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  fab
                  dark
                  small
                  color="cyan"
                  class="mr-3"
                  @click="viewEvent(item)"
                >
                  <v-icon dark small> mdi-eye </v-icon>
                </v-btn>
                <v-btn fab dark small color="red" @click="delEvent(item)">
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="paginate"></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { GET_EVENT, DELETE_EVENT } from "../../graphql/Event";
import AddEvent from "../Teacher/Modal/addEvent";
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "listEvent",
  // apollo query
  apollo: {
    allPelatihan: {
      query: GET_EVENT,
      variables() {
        return { page: this.page, first: this.limit };
      },
      error(err) {
        console.log(err);
      }
    }
  },
  components: {
    AddEvent
  },
  mounted() {
    // console.log(this.scopeTeacher);
    this.$apollo.queries.allPelatihan.refetch();
  },
  computed: {
    ...mapState(["role_id"]),
    loadTable() {
      return this.$apollo.queries.allPelatihan.loading;
    },
    paginate() {
      let page = null;
      if (this.allPelatihan.paginatorInfo) {
        let total = this.allPelatihan.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    }
  },
  data() {
    return {
      allPelatihan: [],
      page: 1,
      limit: 10,
      search: "",
      role: null,
      headers: [
        { text: "id", value: "id" },
        { text: "Nama pelatihan", value: "nama" },
        {
          text: "Modul",
          align: "start",
          sortable: false,
          value: "modul.nama_modul"
        },
        { text: "Kelompok", value: "grup.length" },
        { text: "Peserta", value: "grup.user.length" },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  methods: {
    viewEvent(item) {
      this.$router.push(`event-detail/${item.id}`);
      console.log(item.modul.tipe);
    },
    delEvent(item) {
      Swal.fire({
        title: "Yakin hapus pelatihan?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_EVENT,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.$apollo.queries.allPelatihan.refetch();
              Swal.fire({
                icon: "success",
                title: "Pelatihan telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style>
.profile-teacher {
  display: none;
}
.temp-table {
  width: 100%;
}
.min {
  min-height: 90vh;
}
._bg-default {
  background: #f4f6f9;
}
/* .temp-list {
  background: #f4f6f9;
}
nav {
  background: #f4f6f9;
} */
.module {
  width: calc(100% / 2);
}
.temp-mod {
  width: 100%;
}
</style>
